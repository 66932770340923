import React, { useContext, useEffect, useState } from "react";
import Spacing from "../../Components/Spacing/Spacing";
import { axiosApi, dashboardUrl } from "../../axiosInstance";
import { useParams } from "react-router-dom";
import { UserContext } from "../../Contexts/UseContext";
import Eye from "../../Components/PasswordEye/Eye";
import CloseEye from "../../Components/PasswordEye/CloseEye";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import FormWizardSample from "../../Components/SuperOwnerSignup/SuperOwnerSignup";

const OwnerSignupPage = () => {
  const { salespersoncode } = useParams();
  const { setPageTitle } = useContext(UserContext);

  useEffect(() => {
    setPageTitle("Register as Owner");
  }, []);

  const [input, setInput] = useState({
    name: "",
    email: "",
    phone: "",
    salespersoncode: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [butonLoading, setButonLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (salespersoncode) {
      setInput((prevInput) => ({
        ...prevInput,
        salespersoncode,
      }));
    }
  }, [salespersoncode]);

  const validate = () => {
    const newErrors = {};
    const email = input.email;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    let errorMessages = [];

    // Validate phone number
    if (input.phone && typeof input.phone === "string") {
      const valid = isValidPhoneNumber(input.phone);
      console.log(valid);

      if (!valid) {
        newErrors.phone = "Please enter a valid phone number";
        errorMessages.push("Please enter a valid phone number");
      }
    }

    // Validate email
    if (!emailRegex.test(email)) {
      newErrors.email = "Enter a valid email address";
      errorMessages.push("Enter a valid email address");
    }

    // Validate password length
    if (input.password.length < 6) {
      newErrors.password = "Password should contain minimum 6 characters";
      errorMessages.push("Password should contain minimum 6 characters");
    }

    // Validate password confirmation
    if (input.password !== input.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
      errorMessages.push("Passwords do not match");
    }

    if (errorMessages.length > 0) {
      toast.warning(errorMessages[0]); // Show only the first error
    }

    setErrors(newErrors);
    return errorMessages.length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !input.name ||
      !input.email ||
      !input.phone ||
      !input.password ||
      !input.confirmPassword
    ) {
      toast.warning("Please fill in all input fields");
      return;
    }

    if (validate()) {
      setButonLoading(true);

      const { confirmPassword, ...rest } = input;

      try {
        const data = {
          ...rest,
          user_name: input.email,
        };

        if (!data.salespersoncode) {
          delete data.salespersoncode;
        }

        const response = await axiosApi.post("/v1/auth/sign-up", data);

        Swal.fire({
          title: "Signup successful!",
          text: "A verification email has been sent to your registered email address. Please check your inbox.",
          icon: "success",
          confirmButtonText: "ok",
          allowOutsideClick: false,
        });

        setButonLoading(false);
        setInput({
          name: "",
          email: "",
          phone: "",
          salespersoncode: "",
          password: "",
          confirmPassword: "",
        });
      } catch (error) {
        console.error("Signup error:", error);
        if (error?.response && error?.response?.status === 403) {
          toast.error("Email already exists. Please use a different email!");
        } else {
          toast.error("Signup failed. Please try again!");
        }
        setButonLoading(false);
      } finally {
        setButonLoading(false);
      }
    }
  };

  const handlePhoneChange = (phone) => {
    setInput({ ...input, phone: phone });
    if (phone && typeof phone === "string") {
      const valid = isValidPhoneNumber(phone);
      if (valid) {
        setErrors((prevErrors) => ({ ...prevErrors, phone: "" }));
      }
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    setInput({ ...input, email: value });
    if (emailRegex.test(value)) {
      setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setInput({ ...input, password: value });
    if (value >= 6) {
      setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
    }
    if (value === input?.confirmPassword) {
      setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: "" }));
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setInput({ ...input, confirmPassword: value });
    if (value === input?.password) {
      setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: "" }));
    }
  };

  return (
    <div className="container signup_main">
      <Spacing lg={90} md={90} />

      <div className="booking_container">
        <div className="booking_form_card">
          <form onSubmit={handleSubmit} className="signup_form">
            <h3
              style={{ textAlign: "center" }}
              className="booking_form_card_title"
            >
              Sign up
            </h3>
            <Spacing lg={30} md={20} />

            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Name"
                required
                value={input?.name}
                onChange={(e) => setInput({ ...input, name: e.target.value })}
              />
            </div>

            <PhoneInput
              international
              defaultCountry="IN"
              countryCallingCodeEditable={false}
              value={input?.phone || ""}
              onChange={handlePhoneChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              className={isFocused ? "active_phone_input" : ""}
            />
            {errors?.phone && <p className="text-danger">{errors?.phone}</p>}

            <div className="mt-3">
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Email or Username"
                required
                value={input?.email}
                onChange={handleEmailChange}
                autoComplete="off"
              />
            </div>
            {errors?.email && <p className="text-danger">{errors?.email}</p>}

            <div className="password-input-container mt-3">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                id="password"
                placeholder="Password"
                required
                value={input?.password}
                onChange={handlePasswordChange}
              />
              <div
                className="icon-container"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <Eye /> : <CloseEye />}
              </div>
            </div>
            {errors.password && (
              <p className="text-danger">{errors.password}</p>
            )}

            <div className="password-input-container mt-3">
              <input
                type={showConfirmPassword ? "text" : "password"}
                className={`form-control ${
                  errors?.confirmPassword ? "is-invalid" : ""
                }`}
                id="confirm-password"
                placeholder="Confirm Password"
                required
                value={input?.confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
              <div
                className="icon-container"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <Eye /> : <CloseEye />}
              </div>
            </div>
            {errors.confirmPassword && (
              <p className="text-danger">{errors.confirmPassword}</p>
            )}

            <Spacing lg={40} md={30} />
            <div className="booking_form_card_btn_wrapper">
              <button
                type="submit"
                className="signup_submit_btn"
                disabled={butonLoading}
              >
                {butonLoading ? <span className="loader"></span> : "Sign up"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Spacing lg={100} md={80} />
      <ToastContainer position="top-center" autoClose={2000} />
    </div>
  );
};

export default OwnerSignupPage;
