import React, { useContext, useEffect, useState } from "react";
import Spacing from "../Components/Spacing/Spacing";
import { axiosApi, dashboardUrl } from "../axiosInstance";
import { UserContext } from "../Contexts/UseContext";
import { useLocation, useNavigate } from "react-router-dom";

const OwnerVerification = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isVerifying, setIsVerifying] = useState(true);
  const [verificationStatus, setVerificationStatus] = useState(null);

  // Parse query parameters
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  useEffect(() => {
    if (token) {
      axiosApi
        .post("v1/auth/verifyOwner", { token })
        .then((response) => {
          if (response.status === 201) {
            setVerificationStatus({
              success: true,
              message: "Owner has been successfully verified!",
            });
          }
        })
        .catch((error) => {
          setVerificationStatus({
            success: false,
            message:
              error.response?.data?.message ||
              "Something went wrong during verification.",
          });
        })
        .finally(() => {
          setIsVerifying(false);
        });
    } else {
      setVerificationStatus({
        success: false,
        message: "No token found in the URL. Please check your link.",
      });
      setIsVerifying(false);
    }
  }, [token]);

  const handleRedirect = () => {
    if (verificationStatus?.success) {
      window.location.href = dashboardUrl;
    } else {
      navigate("/owner-signup");
    }
  };

  return (
    <div className="owner-verification">
      <Spacing lg={90} md={90} />
      <div className="verification-container">
        {isVerifying ? (
          <div className="loading-spinner">
            <div className="spinner"></div>
            <p>Verifying Owner...</p>
          </div>
        ) : (
          <div className="verification-message">
            <img
              src={`${process.env.PUBLIC_URL}/icons/${
                verificationStatus?.success ? "check.png" : "remove.png"
              }`}
              alt={verificationStatus?.success ? "Success Icon" : "Error Icon"}
              className="icon"
            />
            <h2>
              {verificationStatus?.success
                ? "Verification Successful"
                : "Verification Failed"}
            </h2>
            <p>{verificationStatus?.message}</p>
            <button
              className={`btn ${
                verificationStatus?.success ? "btn-success" : "btn-danger"
              }`}
              onClick={handleRedirect}
            >
              {verificationStatus?.success ? "Go to Dashboard" : "Retry"}
            </button>
          </div>
        )}
      </div>
      <Spacing lg={90} md={90} />
    </div>
  );
};

export default OwnerVerification;
