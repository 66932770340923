import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import Layout from "./Components/Layout/Layout";
import Layout2 from "./Components/Layout/Layout2";
import Home from "./Pages/Home";
import PostDetails from "./Pages/PostDetails";
import PageNotFound from "./Components/404/PageNotFound";
import DoctorProfilePage from "./Pages/DoctorProfilePage";
import NumberVerification from "./Pages/BookingPages/NumberVerification";
import OtpVerification from "./Pages/BookingPages/OtpVerification";
import SelectPatient from "./Pages/BookingPages/SelectPatient";
import BookingConfirmation from "./Pages/BookingPages/BookingCofirmation";
import OwnerSignupPage from "./Pages/OwnerSignupPage/OwnerSignupPage";
import SubscriptionPlan from "./Pages/SubscriptionPlan/SubscriptionPlan";
import PatientLogin from "./Pages/PatientAuth/PatientLogin";
import PatientRegister from "./Pages/PatientAuth/PatientRegister";
import ForgotPassword from "./Pages/PatientAuth/ForgotPassword";
import NewPassword from "./Pages/PatientAuth/NewPassword";
import DoctorSignupPage from "./Pages/DoctorSignupPage/DoctorSignupPage";
import ClinicSingleView from "./Pages/ClinicSingleView/ClinicSingleView";
import BookStatus from "./Pages/BookStatus/BookStatus";
import PatientList from "./Pages/PatientList/PatientList";
import OwnerVerification from "./Pages/OwnerVerification";

const App = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="post/:postId" element={<PostDetails />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>

      <Route path="/Verification" element={<OwnerVerification />} />

      <Route path="/" element={<Layout2 />}>
        {/* signup page */}
        <Route path="/owner-signup" element={<OwnerSignupPage />} />
        <Route
          path="/owner-signup/:salespersoncode"
          element={<OwnerSignupPage />}
        />
        {/* owner verification page */}

        <Route path="/doctor-signup" element={<DoctorSignupPage />} />
        {/* doctor profile  */}
        <Route
          path="/doctor-profile/:doctorId"
          element={<DoctorProfilePage />}
        />

        {/* guest booking starts here */}
        <Route
          path="/booking/number-verification"
          element={<NumberVerification />}
        />
        <Route path="/booking/otp-verification" element={<OtpVerification />} />
        <Route path="/booking/patient-details" element={<SelectPatient />} />
        <Route
          path="/booking/booking-confirmation"
          element={<BookingConfirmation />}
        />

        <Route path="/bookstatus/bookstatus" element={<BookStatus />} />
        {/* user-pateint booking starts here */}
        <Route path="/booking/select-patient" element={<PatientList />} />

        {/* subscription plans  */}
        <Route path="/subscription-plan" element={<SubscriptionPlan />} />
        {/* patient auth */}
        <Route path="/patient-login" element={<PatientLogin />} />
        <Route path="/patient-register" element={<PatientRegister />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/NewPassword" element={<NewPassword />} />

        {/* clinicsingleview */}
        <Route path="/clinic/:clinicId" element={<ClinicSingleView />} />
      </Route>
    </Routes>
  );
};

export default App;
