import React, { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { UserContext } from "../../Contexts/UseContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Icon } from "@iconify/react";

const Header = ({ data }) => {
  const { userDetails, setUserDetails } = useContext(UserContext);
  const { logo } = data;

  const [mobileToggle, setMobileToggle] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isSticky, setIsSticky] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const handleMobileToggle = () => {
    setMobileToggle(!mobileToggle);
  };

  const handleLogout = () => {
    setUserDetails(null);
    sessionStorage.clear();
    toast.success("Logout Successfully");
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const windowTop = window.scrollY || document.documentElement.scrollTop;

      if (windowTop >= headerHeight) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }

      setLastScrollTop(windowTop);
    };

    const headerHeight =
      document.querySelector(".st-sticky-header").offsetHeight + 100;

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSticky, lastScrollTop]);

  return (
    <header
      className={`st-site-header st-style1 st-sticky-header ${
        isSticky ? "st-sticky-active" : ""
      }`}
    >
      {/* <div className={`st-top-header ${userDetails ? "show" : ""}`}>
        <div className="container st-top-container">
          <div className="st-top-header-in">
            <ul className="st-top-header-list">
              <li>
                <ScrollLink to="contact"> info@mydoctorstime.com </ScrollLink>
              </li>
              <li>
                <ScrollLink to="contact"> +01 - 234 567 890 </ScrollLink>
              </li>
            </ul>
            {userDetails && (
              <button
                style={{
                  cursor: "pointer",
                  userSelect: "none",
                  background: "darkRed",
                }}
                className="st-site-book-now-btn"
                onClick={handleLogout}
              >
                Log out
              </button>
            )}
          </div>
        </div>
      </div> */}
      <div className="st-main-header">
        <div className="container">
          <div className="st-main-header-in">
            <div className="st-main-header-left">
              <ScrollLink to="home" className="st-site-branding">
                <img src={logo} alt="Logo" className="st-site-branding-img" />
              </ScrollLink>
            </div>
            <div className="st-main-header-right">
              <div className="st-nav">
                <ul
                  className={`st-nav-list st-onepage-nav ${
                    mobileToggle ? "d-block" : "none"
                  }`}
                >
                  <li>
                    <ScrollLink
                      to="home"
                      spy={true}
                      duration={500}
                      onClick={() => setMobileToggle(false)}
                    >
                      Home
                    </ScrollLink>
                  </li>
                  {!userDetails && (
                    <li>
                      <Link to={"/doctor-signup"}>For Doctors</Link>
                    </li>
                  )}
                  {!userDetails && (
                    <li>
                      <Link to={"/subscription-plan"}>Partner With Us</Link>
                    </li>
                  )}
                  {userDetails && (
                    <li>
                      <Link to={"/bookstatus/bookstatus"}>My Appointments</Link>
                    </li>
                  )}
                  {userDetails && (
                    <li>
                      <Link>Notifications</Link>
                    </li>
                  )}
                  {userDetails ? (
                    <li
                      ref={dropdownRef}
                      className="log-in-usr-name"
                      onClick={toggleDropdown}
                    >
                      <button
                        style={{
                          color: "white",
                          background: "#006421",
                          fontWeight: "600",
                          fontSize: "14px",
                          paddingLeft: "20px",
                          paddingRight: "20px",
                          border: "none",
                          borderRadius: "4rem",
                          paddingTop: "13px",
                          paddingBottom: "13px",
                          textAlign: "center",
                        }}
                      >
                        <Icon
                          icon="fa6-solid:user"
                          className="user-icon"
                          style={{ marginRight: "6px", marginTop: "-4px" }}
                        />
                        Hi,{" "}
                        {userDetails.user_name
                          .split("@")[0]
                          .charAt(0)
                          .toUpperCase() +
                          userDetails.user_name.split("@")[0].slice(1)}
                        !
                      </button>
                      {dropdownVisible && (
                        <>
                          <div className="user-dropdown-menu">
                            <li>
                              <Icon
                                icon="fa6-solid:user"
                                className="my-profile-icon"
                                style={{ marginRight: "16px" }}
                              />
                              My Profile
                            </li>
                            <li>
                              <Icon
                                icon="fa6-solid:users"
                                className="family-icon"
                                style={{ marginRight: "10px" }}
                              />
                              Add Family
                            </li>
                            <li>
                              <Icon
                                icon="fa6-solid:bell"
                                className="notification-icon"
                                style={{ marginRight: "16px" }}
                              />
                              Notification Settings
                            </li>
                            <li onClick={handleLogout}>
                              <Icon
                                icon="fa6-solid:arrow-right-from-bracket"
                                className="logout-icon"
                                style={{ marginRight: "16px" }}
                              />
                              Logout
                            </li>
                          </div>
                        </>
                      )}
                    </li>
                  ) : (
                    <button
                      style={{
                        color: "white",
                        background: "#006421",
                        fontWeight: "600",
                        fontSize: "14px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        border: "none",
                        borderRadius: "4rem",
                      }}
                    >
                      <Link to="/patient-login">
                        {" "}
                        <Icon
                          icon="fa6-solid:user"
                          className="user-icon"
                          style={{ marginRight: "6px", marginTop: "-4px" }}
                        />
                        Sign In
                      </Link>
                    </button>
                  )}
                </ul>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <div
                    className={`st-munu-toggle ${
                      mobileToggle ? "st-toggle-active" : ""
                    }`}
                    onClick={handleMobileToggle}
                  >
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </header>
  );
};

export default Header;
